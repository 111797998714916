import * as React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";

import * as styles from '../styles/404.module.css';

// const NotFoundPage = () => (
//   <Layout>
//     <Seo title="404: Not found" />
//     <div className={styles.Container}>
//       <h1>404: Not Found</h1>
//       <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
//     </div>
//   </Layout>
// )

// export default NotFoundPage;

import { useEffect } from 'react';
import { navigate } from '@reach/router';

export default () => {
    useEffect(() => {
        navigate('/');
    }, []);
    return null;
};